import React from 'react';
import { Button } from '@mui/material';
import '../../index.css';
interface ButtonRoundProps {
  label: string;
  onClick: () => void;
  color?: string; // Optionally pass a color prop to customize the button color
}

const ButtonRound: React.FC<ButtonRoundProps> = ({
  label,
  onClick,
  color = 'var(--button-color)',
}) => {
  return (
    <Button
      variant="contained"
      fullWidth
      onClick={onClick}
      sx={{
        backgroundColor: color, // Default button color
        color: 'var(--white)',
        borderRadius: '50px', // Fully rounded button
        padding: '10px 20px',
        fontWeight: 'bold',
        fontSize: '16px',
        '&:hover': {
          backgroundColor: color, // Keep the same color on hover
          opacity: 0.9,
        },
      }}
    >
      {label}
    </Button>
  );
};

export default ButtonRound;
