import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PublicClientApplication } from '@azure/msal-browser';
// @ts-ignore
import { MsalProvider } from '@azure/msal-react';

// MSAL Configuration
const msalConfig = {
  auth: {
    clientId: 'fd02d2da-cd64-4e4b-8bfd-a2380c3101e7', // The Application (client) ID from Azure
    authority:
      'https://login.microsoftonline.com/46931982-3d2d-425f-9cf1-da3140aa4689', // The tenant ID or directory ID
    redirectUri: process.env.REACT_APP_REDIRECT_URI, // Redirect URI for local development
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    {/* Wrap the App in the MSAL Provider */}
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
