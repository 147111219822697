import axios from 'axios';

const HPC_API_BASE = process.env.REACT_APP_HPC_API;
const BLOB_UPLOAD_API_BASE = process.env.REACT_APP_BLOB_UPLOAD_API;

export const getHistoricalPrices = async (file_url: string) => {
  return axios.post(
    `${HPC_API_BASE}/retrieve`,
    { file_url },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

export const createPriceComparison = async (
  benchmark: any[],
  new_quotation: any
) => {
  return axios.post(
    `${HPC_API_BASE}/analyze`,
    { benchmark, new_quotation },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
};

// /upload API: Takes a multipart file and file_name as input
export const uploadBlob = async (file: File, file_name: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('file_name', file_name);

  return axios.post(`${BLOB_UPLOAD_API_BASE}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
