import React, { useState, useEffect } from 'react';
import MainContentSection from '../../components/MainContent/MainContentSection';
import MainContent from '../../components/MainContent/MainContent';
import MainContentExtend from '../../components/MainContent/MainContentExtend';
import MainContentChart from '../../components/MainContent/MainContentChart';
import TextContent from '../../components/MainContent/TextContent';
import ChartComponent from '../../components/Data/Chart';
import CarouselWrapper from '../../components/Data/Carousel';
import UploadButton from '../../components/Buttons/UploadButton';
import ButtonRound from '../../components/Buttons/ButtonRound';
import FilterSection from '../../components/Sidebar/FilterSection';
import BaseLayout from '../../layouts/BaseLayout';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectComparisonResult } from '../../redux/comparisonSlice';
import MarkdownDisplay from '../../components/Data/MarkdownDisplay';

interface InflationRateData {
  Year?: { [key: string]: number };
  'Consumer price index'?: { [key: string]: number };
  'Annual change in (%)'?: { [key: string]: number };
}

const HistoricalPriceComparison: React.FC = () => {
  const comparisonResult = useSelector(selectComparisonResult);

  const navigate = useNavigate();
  const [offerDetails, setOfferDetails] = useState('');
  const [inflationRateData, setInflationRateData] = useState(null);

  useEffect(() => {
    if (
      comparisonResult &&
      comparisonResult.data &&
      comparisonResult.data.price_analysis
    ) {
      setOfferDetails(comparisonResult.data.price_analysis);
    }

    if (
      comparisonResult.data.external_factors &&
      comparisonResult.data.external_factors.inflation_rate
    ) {
      try {
        const parsedInflationRate = JSON.parse(
          comparisonResult.data.external_factors.inflation_rate
        );
        setInflationRateData(parsedInflationRate);
      } catch (error) {
        console.error('Error parsing inflation rate data:', error);
      }
    }
  }, [comparisonResult]);

  useEffect(() => {
    if (inflationRateData) {
      console.log('Parsed Inflation Rate Data:', inflationRateData);
      // Now you can use this data to update your charts or display it in your component
    }
  }, [inflationRateData]);

  const [selectedOffer, setSelectedOffer] = useState<string | null>(() => {
    const firstOffer = Object.keys(offerDetails)[0];
    return firstOffer || null;
  });

  const handleOfferClick = (offer: string) => {
    if (offer !== selectedOffer) {
      setSelectedOffer(offer);
    }
  };
  const chartDataHistoricalPrices = {
    labels: [
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
      '2023',
      '2024',
    ],
    data: [0.1, 0.2, 0.15, 0.4, 0.25, 0.3, 0.25, 0.3, 0.2],
    xAxisLabel: 'Delivery Time (Year)',
    yAxisLabel: 'Price per Unit (€)',
  };

  const chartDataInflationRate = {
    labels: [
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
      '2023',
    ],
    data: [10, 30, 20, 50, 40, 70, 60, 90, 80],
    xAxisLabel: 'Year',
    yAxisLabel: 'Inflation Rate (%)',
  };

  const handleNewComparison = () => {
    navigate('/historical-price-comparison/'); // Replace with the actual path you want to navigate to
  };

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [sasUrl, setSasUrl] = useState<string | null>(null);
  // Sidebar content specific to this page
  const sidebarContent = (
    <>
      <Box sx={{ marginBottom: '10px' }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', marginBottom: '16px' }}
        >
          Received Offers
        </Typography>
        <UploadButton label="Upload Document" setSasUrl={setSasUrl} />
        {uploadedFile && <Typography>Uploaded: {uploadedFile.name}</Typography>}
        {uploadStatus && <Typography>{uploadStatus}</Typography>}
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <FilterSection />
      </Box>

      <Box sx={{ marginTop: '1px' }}>
        <ButtonRound label={'New Comparison'} onClick={handleNewComparison} />
      </Box>
    </>
  );

  // Main content specific to this page
  const mainContent = (
    <>
      <MainContentSection title="Historical Price Comparison">
        <MainContent>
          <MarkdownDisplay content={offerDetails} />
        </MainContent>

        <MainContentExtend>
          <MainContentChart title="Historical Prices">
            <ChartComponent
              title="Historical Prices"
              data={chartDataHistoricalPrices}
              xAxisLabel={chartDataHistoricalPrices.xAxisLabel}
              yAxisLabel={chartDataHistoricalPrices.yAxisLabel}
            />
          </MainContentChart>

          {inflationRateData && (
            <MainContentChart title="Inflation Rate">
              <ChartComponent
                title="Inflation Rate"
                data={{
                  labels: Object.values(inflationRateData['Year']),
                  data: Object.values(
                    inflationRateData['Consumer price index']
                  ),
                }}
                xAxisLabel="Year"
                yAxisLabel="Inflation Rate (%)"
              />
            </MainContentChart>
          )}
        </MainContentExtend>
      </MainContentSection>
    </>
  );

  // Pass both sidebarContent and mainContent to the BaseLayout
  return (
    <BaseLayout sidebarContent={sidebarContent} mainContent={mainContent} />
  );
};

export default HistoricalPriceComparison;
