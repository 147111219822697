import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createPriceComparison } from '../services/api';

interface PriceComparisonState {
  data: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: PriceComparisonState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchPriceComparison = createAsyncThunk(
  'priceComparison/fetch',
  async (
    { benchmark, new_quotation }: { benchmark: any[]; new_quotation: any },
    { dispatch }
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await createPriceComparison(benchmark, new_quotation);
      dispatch(setPriceComparison(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred';
      dispatch(setError(errorMessage));
    }
  }
);

const priceComparisonSlice = createSlice({
  name: 'priceComparison',
  initialState,
  reducers: {
    setPriceComparison: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setPriceComparison, setLoading, setError } =
  priceComparisonSlice.actions;
export default priceComparisonSlice.reducer;
