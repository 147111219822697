import React from 'react';
import { Box } from '@mui/material';
import '../../index.css';
import { useTheme } from '../Header/ThemeContext';

interface SidebarProps {
  children: React.ReactNode; // Sidebar content can be passed as children
}

const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const { theme } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor:
          theme === 'light' ? 'var(--background-color)' : 'var(--dark-col)',
        padding: '24px',
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        height: '80vh', // Set height for full viewport minus some space
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {children} {/* Render the content passed as children */}
    </Box>
  );
};

export default Sidebar;
