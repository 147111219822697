import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';

interface TextContentProps {
  title?: string; // Make title optional
  content: string;
}

const TextContent: React.FC<TextContentProps> = ({ title, content }) => {
  const { theme } = useTheme();
  return (
    <Card
      sx={{
        marginBottom: 5,
        marginRight: 3,
        borderRadius: '16px',
        backgroundColor:
          theme === 'light' ? 'var(--background-color)' : 'var(--dark-row)',
        padding: '20px', // Added padding for better spacing
      }}
    >
      <CardContent>
        {title && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold', // Makes the title bold
              color: theme === 'light' ? 'black' : 'white',
              marginBottom: '12px', // Adds space below the title
            }}
            gutterBottom
          >
            {title}
          </Typography>
        )}
        <Typography
          variant="body1" // Increased from 'body2' to make the text bigger
          sx={{
            color: theme === 'light' ? 'black' : 'white',
            lineHeight: 1.7, // Adjust line height for better readability
          }}
        >
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TextContent;
