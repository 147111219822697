import React from 'react';
import '../../index.css';
import { useTheme } from '../Header/ThemeContext';
import {
  List as MuiList,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';

interface ListProps {
  items: { text: string; offer: any }[]; // Each item has 'text' and 'offer'
  showNumbers?: boolean; // Optional prop to toggle numbering
  onItemClick?: (offer: any) => void; // 'offer' is passed to the click handler
}

const List: React.FC<ListProps> = ({
  items,
  showNumbers = true,
  onItemClick,
}) => {
  const { theme } = useTheme();

  return (
    <MuiList sx={{ padding: 0, margin: 0 }}>
      {items.map((item, index) => (
        <ListItem
          key={index}
          divider
          component="button"
          onClick={
            () => onItemClick && onItemClick(item.offer) // Pass the full offer object
          }
          sx={{
            padding: '10px 0',
            marginBottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: theme === 'light' ? 'var(--white)' : 'var(--list-color)',
            fontWeight: 'bold',
            fontSize: '16px',
            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
            backgroundColor:
              theme === 'light' ? 'var(--background-color)' : 'var(--dark-col)',
            border: 'none',
            '&:hover': {
              backgroundColor: theme === 'light' ? 'textSecondary' : '#0f2444',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              transform: 'translateY(-2px)',
            },
            '&:last-child': {
              borderBottom: 'none',
            },
          }}
        >
          {showNumbers && (
            <Box
              sx={{
                marginRight: '10px',
                fontSize: '16px',
                color: theme === 'light' ? 'var(--black)' : 'var(--white)',
              }}
            >
              {index + 1}.
            </Box>
          )}
          <ListItemText
            primary={
              <Typography
                sx={{
                  fontWeight: 'normal',
                  fontSize: '16px',
                  color: theme === 'light' ? 'var(--black)' : 'var(--white)',
                }}
              >
                {item.text}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </MuiList>
  );
};

export default List;
