import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';
interface MainContentProps {
  children: React.ReactNode; // Content of the main area is provided by the page
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  const { theme } = useTheme();
  return (
    <Box
      sx={{
        border: 'none',
        backgroundColor:
          theme === 'light' ? 'var(--background-color)' : 'var(--dark-col)',
        borderRight: '1px solid #EEEEFC',
      }}
    >
      {children}
    </Box>
  );
};

export default MainContent;
