import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from '../Header/ThemeContext';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler // Enable fill area under the line
);

interface ChartProps {
  title: string;
  data: {
    labels: string[];
    data: number[];
  };
  xAxisLabel: string; // Add x-axis label as a prop
  yAxisLabel: string; // Add y-axis label as a prop
}

const ChartComponent: React.FC<ChartProps> = ({
  title,
  data,
  xAxisLabel,
  yAxisLabel,
}) => {
  const { theme } = useTheme();
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: title,
        data: data.data,
        fill: false,
        backgroundColor:
          theme === 'light'
            ? 'var(--background-color)'
            : 'var(--background-dark)', // Light blue fill under the line
        borderColor: '#3f51b5', // Blue line
        borderWidth: 2,
        pointBackgroundColor: '#3f51b5', // Blue points
        pointRadius: 0,
        pointHoverRadius: 7, // Larger point on hover
        tension: 0.4, // Smooth line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index' as const, // Specify the interaction mode correctly with `as const`
        intersect: false, // Show tooltip and vertical line even if not hovering directly on a point
        callbacks: {
          label: function (tooltipItem: any) {
            return `€${tooltipItem.raw.toFixed(2)}`; // Format tooltip value as € with two decimals
          },
        },
      },
      legend: {
        display: false, // Disable legend display
      },
    },
    scales: {
      x: {
        grid: {
          display: true, // Show vertical grid lines
          color: 'rgba(0, 0, 0, 0.05)', // Light gray vertical grid lines
        },
        title: {
          display: true,
          text: xAxisLabel, // Display x-axis label
          color: theme === 'light' ? 'textSecondary' : 'white',
        },
        ticks: {
          color: theme === 'light' ? 'textSecondary' : 'white',
        },
      },
      y: {
        grid: {
          display: true, // Show horizontal grid lines
          color: 'rgba(0, 0, 0, 0.05)', // Light gray horizontal grid lines
        },
        title: {
          display: true,
          text: yAxisLabel, // Display y-axis label
          color: theme === 'light' ? 'textSecondary' : 'white',
        },
        ticks: {
          color: theme === 'light' ? 'textSecondary' : 'white', // Set the color of y-axis ticks
        },
      },
    },
    interaction: {
      mode: 'index' as const, // Specify the mode correctly here
      intersect: false, // No need to hover exactly on the point
    },
    hover: {
      mode: 'index' as const, // Ensure hover line appears across the entire chart
      intersect: false,
    },
  };

  return (
    <div style={{ width: '100%', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ChartComponent;
