import React, { useState } from 'react';
import { Box, Button, Typography, Divider } from '@mui/material';
import CollapsibleSection from './CollapsibleSection';
import FieldInput from './FieldInput';
import TagList from './TagList';
import { useTheme } from '../Header/ThemeContext';

interface Filter {
  field_name: string;
  values: string[];
}

const FilterSection: React.FC = () => {
  const { theme } = useTheme();
  const [filters, setFilters] = useState<Filter[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const [isAddingFilter, setIsAddingFilter] = useState(false);

  const handleAddFilter = () => {
    const newFilter = { field_name: '', values: [] };
    setFilters([...filters, newFilter]);
    setFilterValues([...filterValues, '']);
    setExpandedIndex(filters.length);
    setIsAddingFilter(false);
  };

  const handleFieldNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedFilters = [...filters];
    updatedFilters[index].field_name = e.target.value;
    setFilters(updatedFilters);
  };

  const handleValueSubmit = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Enter' && filterValues[index].trim()) {
      const updatedFilters = [...filters];
      updatedFilters[index].values.push(filterValues[index]);
      setFilters(updatedFilters);
      const updatedFilterValues = [...filterValues];
      updatedFilterValues[index] = '';
      setFilterValues(updatedFilterValues);
    }
  };

  const handleRemoveTag = (filterIndex: number, tagIndex: number) => {
    const updatedFilters = [...filters];
    updatedFilters[filterIndex].values.splice(tagIndex, 1);
    setFilters(updatedFilters);
  };

  const toggleExpanded = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginBottom: '10px',
          color: theme === 'light' ? 'black' : 'white',
        }}
      >
        Filter Data
      </Typography>
      <Divider sx={{ marginBottom: '20px' }} />

      {filters.map((filter, index) => (
        <CollapsibleSection
          key={index}
          title={filter.field_name || 'New Filter'}
          expanded={expandedIndex === index}
          onToggle={() => toggleExpanded(index)}
        >
          {/* Field Name Input */}
          <FieldInput
            label="Field Name"
            value={filter.field_name}
            onChange={(e) => handleFieldNameChange(e, index)}
            placeholder="Enter field name"
          />

          {/* Value Input */}
          <FieldInput
            label="Value"
            value={filterValues[index] || ''}
            onChange={(e) => {
              const updatedFilterValues = [...filterValues];
              updatedFilterValues[index] = e.target.value;
              setFilterValues(updatedFilterValues);
            }}
            onKeyDown={(e) => handleValueSubmit(e, index)}
            placeholder="Type value and press Enter"
          />

          <TagList
            tags={filter.values}
            onDelete={(tagIndex) => handleRemoveTag(index, tagIndex)}
          />
        </CollapsibleSection>
      ))}

      <Button
        variant="outlined"
        fullWidth
        sx={{ marginTop: '20px' }}
        onClick={handleAddFilter}
      >
        + Add Filter
      </Button>
    </Box>
  );
};

export default FilterSection;
