import React, { useState, useRef } from 'react';
import { Box, InputBase, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../assets/images/Blue typo.svg';
import Darklogo from '../../assets/images/White.png';
import Settings from './Settings'; // Import the new Settings component
import '../../index.css';
import { useTheme } from './ThemeContext';
const Header: React.FC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null); // Create a reference to the input
  const { theme } = useTheme();

  // Function to handle pressing "Enter"
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur(); // Remove focus from the input
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        backgroundColor:
          theme === 'light'
            ? 'var(--background-color)'
            : 'linear-gradient(135deg, #001F54 0%, #003D80 50%, #0057A1 100%)',
        height: '70px',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <Grid container alignItems="center">
        {/* Left Section - Logo aligned above the Sidebar */}
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={theme === 'light' ? logo : Darklogo}
            alt="BrightSide Technologies"
            style={{ height: '50px' }}
          />
        </Grid>

        {/* Center Section - Search bar aligned with MainContent */}
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              Color: theme === 'dark' ? 'white' : 'black',
              backgroundColor:
                theme === 'dark'
                  ? 'var(--background-dark)'
                  : 'var(--search-box-color)',
              borderRadius: '8px',
              padding: '0 10px',
              width: isFocused ? '70%' : '60%', // Start with smaller width, expand on focus/hover
              height: isFocused ? '50px' : '40px', // Start smaller, expand on focus/hover
              transition: 'all 0.3s ease', // Smooth transition for hover and focus
              boxShadow: isFocused ? '0 0 10px rgba(0, 0, 0, 0.1)' : 'none', // Slight shadow on focus
            }}
            onMouseEnter={() => setIsFocused(true)} // Set focus on hover
            onMouseLeave={() => !isFocused && setIsFocused(false)} // Only lose focus if not already focused
          >
            <SearchIcon sx={{ color: 'var(--button-color)' }} />{' '}
            {/* Updated magnifying glass color */}
            <InputBase
              placeholder="Search here..."
              sx={{
                ml: 1,
                flex: 1,
                fontSize: '16px',
                color: theme === 'dark' ? '#fff' : '#000',
              }}
              inputRef={inputRef} // Attach the reference to the input
              onKeyDown={handleKeyDown} // Handle keydown for Enter
              onFocus={() => setIsFocused(true)} // Keep focused when the user types
              onBlur={() => setIsFocused(false)} // Collapse when losing focus
            />
          </Box>
        </Grid>

        {/* Right Section - Settings component */}
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Settings /> {/* Include the new Settings component */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
