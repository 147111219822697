import React from 'react';
import { TextField } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';

interface FieldInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onBlur?: () => void;
  placeholder?: string;
}

const FieldInput: React.FC<FieldInputProps> = ({
  label,
  value,
  onChange,
  onKeyDown,
  onBlur,
  placeholder,
}) => {
  const { theme } = useTheme();

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      fullWidth
      placeholder={placeholder}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
        style: {
          color: theme === 'light' ? 'black' : 'white', // Theme-based label color
        },
      }}
      sx={{
        marginBottom: '10px',
        '& .MuiInputBase-input': {
          color: theme === 'light' ? 'black' : 'white', // Input text color
        },
        '& .MuiInputBase-input::placeholder': {
          color: theme === 'light' ? 'gray' : 'lightgray', // Placeholder color
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: theme === 'light' ? 'black' : 'white', // Border color
          },
          '&:hover fieldset': {
            borderColor: theme === 'light' ? 'black' : 'white', // Hover border color
          },
          '&.Mui-focused fieldset': {
            borderColor: theme === 'light' ? 'black' : 'white', // Focused border color
          },
        },
      }}
    />
  );
};

export default FieldInput;
