import React from 'react';
import {
  Box,
  IconButton,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import { useMsal } from '@azure/msal-react';
import { SignInButton } from '../Buttons/Login';
import { useTheme } from './ThemeContext';

const Settings: React.FC = () => {
  const { accounts } = useMsal();
  const { theme, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const lightColor = '#57667C';
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1px',
        padding: '8px',
        backgroundColor:
          theme === 'light' ? 'var(--background-color)' : 'var(--dark-col)',
        borderRadius: '60px',
        border: theme === 'light' ? '1px solid #ccc' : '1px solid #0057A1',
        height: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            theme === 'light' ? 'var(--background-color)' : 'var(--dark-col)',
          borderRadius: '100px',
          padding: '5px 10px',
          border: theme === 'light' ? '1px solid #ccc' : '1px solid #0057A1',
          height: '18px',
        }}
      >
        <IconButton onClick={toggleTheme}>
          {theme === 'dark' ? (
            <LightModeIcon sx={{ color: lightColor }} />
          ) : (
            <DarkModeIcon
              sx={{
                color: '#57667C',
              }}
            />
          )}
        </IconButton>
        <IconButton onClick={toggleTheme}>
          {theme === 'light' ? (
            <LightModeIcon
              sx={{
                color: '#4E5DFF',
              }}
            />
          ) : (
            <DarkModeIcon sx={{ color: '#4E5DFF' }} />
          )}
        </IconButton>
      </Box>

      <IconButton
        onClick={handleOpenMenu}
        sx={{
          padding: '10px',
        }}
      >
        <Tooltip title="Settings">
          <SettingsIcon sx={{ color: '#57667C' }} />
        </Tooltip>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          mt: '45px',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleCloseMenu}>Profile</MenuItem>
        <Divider />
        {accounts.length > 0 ? (
          <MenuItem onClick={handleCloseMenu}>Sign Out</MenuItem>
        ) : (
          <MenuItem onClick={handleCloseMenu}>Sign In</MenuItem>
        )}
      </Menu>

      {accounts.length > 0 ? (
        <Avatar
          alt="User Profile"
          sx={{
            width: 24,
            height: 24,
            bgcolor: '#57667C',
          }}
        />
      ) : (
        <SignInButton />
      )}
    </Box>
  );
};

export default Settings;
