import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import List from '../../components/Data/List';
import PrettyJsonDisplay from '../../components/Data/PrettyJson';
import ButtonRound from '../../components/Buttons/ButtonRound';
import UploadButton from '../../components/Buttons/UploadButton';
import FilterSection from '../../components/Sidebar/FilterSection';
import BaseLayout from '../../layouts/BaseLayout';
import MainContentSection from '../../components/MainContent/MainContentSection';
import TextContent from '../../components/MainContent/TextContent';
import { useDispatch } from 'react-redux';
import { getHistoricalPrices, createPriceComparison } from '../../services/api'; // Import the API functions
import { setComparisonResult } from '../../redux/comparisonSlice';
import MainContent from '../../components/MainContent/MainContent';
import MainContentExtend from '../../components/MainContent/MainContentExtend';

const ComparisonDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [offers, setOffers] = useState<any[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<any | null>(null);

  // State for storing sas_url and API response
  const [sasUrl, setSasUrl] = useState<string | null>(null);
  const [quotationData, setQuotationData] = useState<{
    similarQuotations: any[];
    quotationAnalysis: any;
  } | null>(null);

  // This function is triggered when the user clicks "Find Similar Offers"
  const handleFindSimilarOffers = async () => {
    if (!sasUrl) {
      console.log('No sas url defined');
      return;
    }

    setIsLoading(true);
    try {
      const response = await getHistoricalPrices(sasUrl);
      console.log(response);
      setQuotationData({
        similarQuotations: response.data.similarQuotations,
        quotationAnalysis: response.data.quotationAnalysis,
      });
      setOffers(response.data.similarQuotations);

      // Automatically select the first offer if available
      if (response.data.similarQuotations.length > 0) {
        setSelectedOffer(response.data.similarQuotations[0]);
      }
    } catch (error) {
      console.error('Error fetching similar offers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOfferClick = (offer: any) => {
    console.log(offer);
    setSelectedOffer(offer);
  };

  const handleRunComparison = async () => {
    if (!quotationData) {
      console.error('No data available for comparison');
      return;
    }
    setIsLoading(true);
    try {
      const response = await createPriceComparison(
        quotationData.similarQuotations,
        quotationData.quotationAnalysis
      );
      console.log('Comparison Result:', response);
      dispatch(setComparisonResult(response));
      setIsLoading(false);
      navigate(`/historical-price-comparison-analysis`);
      // Optionally: Navigate or handle the comparison result here
    } catch (error) {
      console.error('Error running comparison:', error);
    }
  };

  // Sidebar content with the updated UploadButton that sets the sas_url
  const sidebarContent = (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', marginBottom: '16px' }}
        >
          Received Offers
        </Typography>

        <UploadButton label="Upload Document" setSasUrl={setSasUrl} />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <FilterSection />
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <ButtonRound
          label="Find Similar Offers"
          onClick={handleFindSimilarOffers}
        />
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <ButtonRound
          label="Compare Quotations"
          onClick={handleRunComparison} // Trigger the comparison API call
        />
      </Box>
    </>
  );

  // Main content
  const mainContent = isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress />
    </Box>
  ) : offers.length === 0 ? (
    <Typography variant="h5">
      Find similar offers to create a historical price comparison
    </Typography>
  ) : (
    <>
      <MainContentSection title="Similar Quotations">
        <MainContentExtend>
          <MainContent>
            <List
              items={offers.map((offer) => ({
                text: offer['company'], // Displaying company name
                offer, // Pass the entire offer object
              }))}
              onItemClick={(offer) => handleOfferClick(offer)} // Handle the click here
            />
          </MainContent>
          {selectedOffer && (
            <MainContent>
              <TextContent
                title={`${selectedOffer['company']} - Summary`}
                content={selectedOffer['general_description']}
              />
            </MainContent>
          )}
        </MainContentExtend>
        <MainContentExtend>
          {selectedOffer ? (
            <MainContent>
              <embed
                src={
                  selectedOffer['fileUrl'] ||
                  'https://lbbp.nrw.de/sites/default/files/degov_demo_content/dummy.pdf'
                }
                type="application/pdf"
                width="100%"
                height="800px"
              />
            </MainContent>
          ) : (
            <Typography variant="h6">
              Please select an offer to view details
            </Typography>
          )}
        </MainContentExtend>
      </MainContentSection>
    </>
  );

  return (
    <BaseLayout sidebarContent={sidebarContent} mainContent={mainContent} />
  );
};

export default ComparisonDetails;
