import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import historicalPricesReducer from './HistoricalPricesSlice';
import priceComparisonReducer from './PriceComparisonSlice';
import blobUploadReducer from './BlobUploadSlice';
import comparisonReducer from './comparisonSlice';

export const store = configureStore({
  reducer: {
    historicalPrices: historicalPricesReducer,
    priceComparison: priceComparisonReducer,
    blobUpload: blobUploadReducer,
    comparison: comparisonReducer,
  },
});

// Types for TypeScript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Typed hooks for better TypeScript support
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
