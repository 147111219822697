import React, { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useAppDispatch, useAppSelector } from '../../redux/store'; // Import your typed hooks
import { uploadBlobAsync } from '../../redux/BlobUploadSlice'; // Adjust the import path as necessary

interface UploadButtonProps {
  label: string;
  setSasUrl: (url: string) => void; // Pass the function to set SAS URL in the parent
}

const UploadButton: React.FC<UploadButtonProps> = ({ label, setSasUrl }) => {
  const dispatch = useAppDispatch();
  const { loading, data, error } = useAppSelector((state) => state.blobUpload); // Access Redux state

  const [uploadedFileName, setUploadedFileName] = React.useState<string | null>(
    null
  );

  const handleFileUpload = (file: File) => {
    // Dispatch the upload action with the file and its name
    dispatch(uploadBlobAsync({ file, file_name: file.name }));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFileName(file.name); // Set uploaded file name immediately
      handleFileUpload(file);
    }
  };

  React.useEffect(() => {
    if (data) {
      console.log('File uploaded successfully:', data);
      setSasUrl(data.sas_url); // Pass the SAS URL back to the parent
    }
  }, [data, setSasUrl]);

  React.useEffect(() => {
    if (error) {
      console.error('Error uploading file:', error);
    }
  }, [error]);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        component="label"
        sx={{
          backgroundColor: 'var(--upload-button-color)',
          borderRadius: '12px',
          padding: { xs: '16px', sm: '20px', md: '24px', lg: '30px' },
          textAlign: 'center',
          color: 'var(--white)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          transition: 'transform 0.4s ease, background-color 0.4s ease',
          '&:hover': {
            backgroundColor: 'var(--upload-button-hover)',
            transform: 'scale(1.03)',
          },
        }}
      >
        <CloudUploadIcon
          sx={{
            fontSize: { xs: '30px', sm: '35px', md: '40px', lg: '50px' },
            marginBottom: { xs: '8px', sm: '10px', md: '12px' },
          }}
        />
        <Typography
          variant="h6"
          sx={{
            color: 'var(--white)',
            fontWeight: 'bold',
            marginBottom: { xs: '6px', sm: '7px', md: '8px' },
            fontSize: { xs: '14px', sm: '16px', md: '18px' },
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: 'var(--white)',
            fontSize: { xs: '10px', sm: '12px', md: '14px' },
          }}
        >
          (PDF, PPTX, DOCX)
        </Typography>
        <input type="file" hidden onChange={handleFileChange} />
      </Box>

      {loading && <Typography variant="body2">Uploading...</Typography>}
      {uploadedFileName && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <InsertDriveFileIcon sx={{ fontSize: '30px', marginRight: '10px' }} />
          <Typography variant="body1">{uploadedFileName}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadButton;
