import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';
interface MainContentContainerProps {
  title?: string;
  children: React.ReactNode;
  columns?: number; // Default to 2 columns, but it can be dynamically configured
}

const MainContentContainer: React.FC<MainContentContainerProps> = ({
  title,
  children,
}) => {
  const { theme } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor:
          theme === 'light' ? 'var(--background-color)' : 'var(--dark-col)',
        borderRadius: '16px',
        padding: '20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Add shadow for depth
        height: '80vh',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      <Box>
        {title && (
          <Typography
            variant="h6"
            sx={{
              marginBottom: '10px',
              fontWeight: 'bold',
              borderBottom: '1px solid #000',
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default MainContentContainer;
