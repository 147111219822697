import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { uploadBlob } from '../services/api';

interface BlobUploadState {
  data: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: BlobUploadState = {
  data: null,
  loading: false,
  error: null,
};

export const uploadBlobAsync = createAsyncThunk(
  'blobUpload/upload',
  async (
    { file, file_name }: { file: File; file_name: string },
    { dispatch }
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await uploadBlob(file, file_name);
      dispatch(setBlobUploadResult(response.data));
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred';
      dispatch(setError(errorMessage));
    }
  }
);

const blobUploadSlice = createSlice({
  name: 'blobUpload',
  initialState,
  reducers: {
    setBlobUploadResult: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setBlobUploadResult, setLoading, setError } =
  blobUploadSlice.actions;
export default blobUploadSlice.reducer;
