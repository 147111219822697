import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import HistoricalPriceComparison from './pages/HistoricalPriceComparison/Main';
import ComparisonDetails from './pages/HistoricalPriceComparison/ComparisonDetails';
import AnalysisPage from './pages/HistoricalPriceComparison/AnalysisPage';
import { ThemeProvider } from './components/Header/ThemeContext';

// @ts-ignore
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const App: React.FC = () => {
  const authenticationRequest = {
    scopes: ['User.Read'],
  };

  return (
    <ThemeProvider>
      <Router>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authenticationRequest}
        >
          <Routes>
            {/* Redirect from root to historical price comparison */}
            <Route
              path="/"
              element={<Navigate to="/historical-price-comparison/" replace />}
            />

            {/* Main Price Comparison Page */}
            {/*<Route*/}
            {/*  path="/historical-price-comparison/"*/}
            {/*  element={<HistoricalPriceComparison />}*/}
            {/*/>*/}

            {/* Dynamic Route for Specific Price Comparison */}
            <Route
              path="/historical-price-comparison/"
              element={<ComparisonDetails />}
            />

            {/* Dynamic Route for Analysis of a Specific Comparison */}
            <Route
              path="/historical-price-comparison-analysis/"
              element={<AnalysisPage />}
            />
          </Routes>
        </MsalAuthenticationTemplate>
      </Router>
    </ThemeProvider>
  );
};

export default App;
