import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHistoricalPrices } from '../services/api';

interface HistoricalPricesState {
  data: any[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: HistoricalPricesState = {
  data: null,
  loading: false,
  error: null,
};

const historicalPricesSlice = createSlice({
  name: 'historicalPrices',
  initialState,
  reducers: {
    setHistoricalPrices: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const fetchHistoricalPrices = createAsyncThunk(
  'historicalPrices/fetch',
  async (file_url: string, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await getHistoricalPrices(file_url);
      dispatch(setHistoricalPrices(response.data));
    } catch (error) {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(setError(errorMessage));
    }
  }
);

export const { setHistoricalPrices, setLoading, setError } =
  historicalPricesSlice.actions;
export default historicalPricesSlice.reducer;
