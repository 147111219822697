import React from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CollapsibleSectionProps {
  title: string;
  expanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  expanded,
  onToggle,
  children,
}) => {
  return (
    <Box sx={{ marginBottom: '5px' }}>
      <Box
        onClick={onToggle}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '10px 0',
          borderBottom: '1px solid #E0E0E0', // Light border for the list item look
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
        >
          {title}
        </Typography>
        <ExpandMoreIcon
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsibleSection;
