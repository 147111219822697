// src/redux/comparisonSlice.tsx

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ComparisonState {
  comparisonResult: any | null;
}

const initialState: ComparisonState = {
  comparisonResult: null,
};

const comparisonSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    setComparisonResult: (state, action: PayloadAction<any>) => {
      state.comparisonResult = action.payload;
    },
  },
});

export const { setComparisonResult } = comparisonSlice.actions;

export default comparisonSlice.reducer;

// Selector
export const selectComparisonResult = (state: {
  comparison: ComparisonState;
}) => state.comparison.comparisonResult;
