import React from 'react';
import { Box, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface TagListProps {
  tags: string[];
  onDelete: (index: number) => void;
}

const TagList: React.FC<TagListProps> = ({ tags, onDelete }) => {
  return (
    <Box>
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => onDelete(index)}
          sx={{ marginRight: '5px', marginBottom: '5px' }}
          deleteIcon={<CloseIcon />}
        />
      ))}
    </Box>
  );
};

export default TagList;
