import React from 'react';
import { Button } from '@mui/material';
import '../../index.css';
// @ts-ignore
import { useMsal } from '@azure/msal-react';

const SignInButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect({
      scopes: ['User.Read'], // Scopes you need access to
    });
  };

  return (
    <Button
      onClick={handleLogin}
      sx={{
        backgroundColor: 'var(--button-color)', // Default button color
        color: 'var(--white)',
        borderRadius: '20px',
        height: '25px', // Fully rounded button
        padding: '10px 20px',
        fontWeight: 'bold',
        fontSize: '12px',
        '&:hover': {
          backgroundColor: 'var(--button-color)', // Keep the same color on hover
          opacity: 0.9,
        },
      }}
    >
      Sign In
    </Button>
  );
};

const SignOutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return <button onClick={handleLogout}>Sign Out</button>;
};

export { SignInButton, SignOutButton };
