import React from 'react';
import { Box } from '@mui/material';

interface MainContentSectionProps {
  title?: string;
  children: React.ReactNode;
}

const MainContentExtend: React.FC<MainContentSectionProps> = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '200px',
      }}
    >
      {children}
    </Box>
  );
};

export default MainContentExtend;
