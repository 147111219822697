import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';
interface MainContentSectionProps {
  title?: string;
  children: React.ReactNode;
}

const MainContentChart: React.FC<MainContentSectionProps> = ({
  title,
  children,
}) => {
  const { theme } = useTheme();
  return (
    <Card
      sx={{
        backgroundColor:
          theme === 'light'
            ? 'var(--background-color)'
            : 'var(--background-dark)',
        marginTop: 3,
        marginLeft: 3,
        borderRadius: '16px',
      }}
    >
      <CardContent>
        {title && (
          <Typography
            variant="h6"
            sx={{
              marginBottom: '10px',
              fontWeight: 'bold',
              color: theme === 'light' ? 'textSecondary' : 'white',
            }}
          >
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  );
};

export default MainContentChart;
