import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';
interface MainContentSectionProps {
  title?: string;
  children: React.ReactNode;
  columns?: number;
}

const MainContentSection: React.FC<MainContentSectionProps> = React.memo(
  ({ title, children, columns = 2 }) => {
    const { theme } = useTheme();
    return (
      <Box>
        {title && (
          <Typography
            variant="h6"
            component="h6"
            sx={{
              backgroundColor:
                theme === 'light'
                  ? 'var(--background-color)'
                  : 'var(--dark-col)',
              marginBottom: '10px',
              fontWeight: 'bold',
              borderBottom: '1px solid #EEEEFC',
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gap: '20px',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
);

export default MainContentSection;
