import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Typography, Paper } from '@mui/material';
import { useTheme } from '../Header/ThemeContext';
import '../../index.css';

interface MarkdownDisplayProps {
  content?: string;
}

const MarkdownDisplay: React.FC<MarkdownDisplayProps> = ({ content }) => {
  const { theme } = useTheme();
  return (
    <Box
      sx={{
        border: 'none', // Ensuring the Box has no border
        backgroundColor: theme === 'light' ? '#f5f6f8' : 'var(--dark-row)',
        borderRadius: 0, // Remove any border radius if necessary
      }}
    >
      <Paper
        elevation={0} // Set elevation to 0 to remove shadow and border
        sx={{
          border: 'none', // Explicitly set border to none
          padding: 1,
          backgroundColor: theme === 'light' ? '#f5f6f8' : 'var(--dark-col)',
          boxShadow: 'none', // Remove any box shadow
          borderRadius: 0, // Remove border radius if needed
          color: theme === 'light' ? 'black' : 'white',
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: theme === 'light' ? 'black' : 'white', // Set color based on theme
          }}
        >
          Summary
        </Typography>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Paper>
    </Box>
  );
};

export default MarkdownDisplay;
