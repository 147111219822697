import React from 'react';
import { Box, Grid } from '@mui/material';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import MainContentContainer from '../components/MainContent/MainContentContainer';
import { useTheme } from '../components/Header/ThemeContext';
import '../index.css';

interface BaseLayoutProps {
  sidebarContent: React.ReactNode; // Content for the sidebar
  mainContent: React.ReactNode; // Content for the main area
  mainContentColumns?: number; // Optional number of columns for main content
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
  sidebarContent,
  mainContent,
  mainContentColumns = 2, // Default to 2 columns for the main content
}) => {
  const { theme } = useTheme();

  return (
    <div className="splash-background">
      <div className="overlay">
        <Box
          sx={{
            backgroundColor:
              theme === 'light'
                ? 'var(--background-color)'
                : 'linear-gradient(135deg, #001F54 0%, #003D80 50%, #0057A1 100%)',
            color: theme === 'light' ? 'black' : 'white', // Set font color based on theme
            minHeight: '100vh', // Ensure layout fills the viewport height
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Header at the top */}
          <Header />

          {/* Adjust layout to account for the fixed header */}
          <Box
            sx={{
              flex: 1,
              paddingTop: '100px',
              paddingX: '20px',
              overflowY: 'auto',
              display: 'flex',
            }}
          >
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              {/* Sidebar on the left */}
              <Grid
                item
                xs={12}
                md={3}
                sx={{ position: 'sticky', top: '100px' }}
              >
                <Sidebar>{sidebarContent}</Sidebar>{' '}
                {/* Render sidebar content dynamically */}
              </Grid>

              {/* Main Content on the right */}
              <Grid item xs={12} md={9} sx={{ marginBottom: '30px' }}>
                <MainContentContainer columns={mainContentColumns}>
                  {mainContent} {/* Render main content dynamically */}
                </MainContentContainer>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default BaseLayout;
